.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ad-app {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.ad-topnav {
  font-size: calc(10px + 1vmin);
  font-weight: bold;
}
/* .ad-nav {
  background-color: #1e2420;
} */
.ad-nav.nav .nav-link.active {
  color: #ffffff;
  /* //background-color: #067b2d;
  // border-color: #32bf5d; */
}

.ad-nav.nav .nav-link {
  color: #98a99d;
  /* //background-color: #067b2d;
  // border-color: #125d29;
  // border-bottom-color:  #32bf5d; */
}

.ad-nav.nav .nav-link:hover {
  color: #ffffff;
  /* //background-color: #067b2d;
  // border-color: #32bf5d; */
}

.ad-homeimg {
  width: 100%;
  height: 30vh;
}

.ad-connectdata {
  /* background-color: #000; */
  background-color: #282c34;
  border-radius: 1em;
  border-color: #9ea4ab;
  border-width: 3px;
  border-style: dashed;
  /* min-height: 20%; */
}

.ad-supply {
  
  background-color: #b9d2c7;
  color: #0f5132;

  /* background-color: #005bb5; */

  border-radius: 1em;
  border-color: #011f3d;
  border-width: 1px;
  border-style: solid;

  /* font-weight: 500;
  line-height: 1.2;
  font-size: ; */
  
}

/* .ad-campaign {
  background-color: #b9d2c7;
  color: #0f5132;
  border-radius: 1em;
  border-color: #011f3d;
  border-width: 1px;
  border-style: solid;
}
.ad-campaign-top {
  background-color: #d1e7dd;
  border-radius: 1em 1em 0 0;
}
.ad-campaign-bot {
  background-color: #d1e7dd;
  border-radius: 0 0 1em 1em;
} */

.ad-campaign {
  background-color: #3d5d9d;
  color: #ffffff;
  border-radius: 1em;
  border-color: #011f3d;
  border-width: 1px;
  border-style: solid;
}
.ad-campaign-top {
  background-color: #737373;
  border-radius: 1em 1em 0 0;
}
.ad-campaign-bot {
  background-color: #737373;
  border-radius: 0 0 1em 1em;
}


.ad-homelogo {
  width: 100px;
  height: 30px;
}
.ad-homelogo2 {
  width: 300px;
  height: 30px;
}

.ad-bigsec {
  min-height: 50vh;;
}

.ad-social-link a {
  color: #98a99d;
  text-decoration: none;
}

.ad-social-link a:hover {
  color: #ffffff;
  text-decoration: none;
}